<template>
  <travio-center-container grid-width='2/3'>
    <h2 style="color:#636363" class="mb-4">Reward Rules</h2>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-4 sm:mb-0 mb-2" color="primary" @click="onAddRule" type="filled">Add</vs-button>      
          <vs-button class="sm:mb-0 mb-2" color="primary" @click="onManageRewards" type="border">Manage Rewards</vs-button>      

          <vs-input
            class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
        </div>
      </div>
       <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rewardRulesList"
        :gridOptions="gridOptions"
        :autoGroupColumnDef="autoGroupColumnDef"
        rowSelection="single"
        :pagination="false"
        :context="context"
        :suppressPaginationPanel="true"
      >
      </ag-grid-vue>
      
      
    </vx-card>
  
  </travio-center-container>
</template>

<script>
    
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';
import CellRendererActiveCheckbox from './cell-renderers/CellRendererActiveCheckbox.vue'
import CellRendererRuleActions from './cell-renderers/CellRendererRuleActions.vue'
import { format } from 'date-fns'

export default {
  components: {
    AgGridVue,
    CellRendererActiveCheckbox,
    CellRendererRuleActions
  },
  props: {
    applicationId: { required: true}
  },
  data () {
    return {
      rewardRulesList: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererActiveCheckbox,
        CellRendererRuleActions
      },
      context: null,
      searchQuery: ''
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    if (!this.appHasFeature('rewards')) {
      this.$router.push('/error-404')
    }
    // this.rewardRulesList = [
    //   { id: 1, ruleName: 'Rule 1', rewardName: 'Reward Name 1', active: true, createdDate: '2022-03-17' },
    //   { id: 2, ruleName: 'Rule 2', rewardName: 'Reward Name 2', active: true, createdDate: '2022-05-09' },
    // ]

    this.$vs.loading()

    try {
      const response = await this.$http.get(`api/rewardRules/apps/${this.applicationId}`)
      this.rewardRulesList = response.data.rewardRules

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  beforeMount() {

    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'id', hide: true },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererRuleActions) },
      { headerName: 'Name', field: 'ruleName', width: 280,  suppressSizeToFit: false },
      { headerName: 'Reward Name', field: 'rewardName', width: 280,  suppressSizeToFit: false },
      { headerName: 'Active', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActiveCheckbox) },
      { 
        headerName: 'Created', 
        field: 'createdDate',
        width: 250,
        suppressSizeToFit: true,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateFormat)
      },
      { field: 'isActive', hide: true },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    onAddRule () {
      this.$router.push({name: 'application-tools-reward-rules-create', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
    onEditRule (ruleId) {
      this.$router.push({name: 'application-tools-reward-rules-edit', 
        params: { 
          applicationId: this.applicationId,
          ruleId: ruleId 
        }
      })
    },
    onViewRule (ruleId) {
      this.$router.push({name: 'application-tools-reward-rules-view', 
        params: { 
          applicationId: this.applicationId,
          ruleId: ruleId 
        }
      })
    },
    async deleteRule (ruleId) {
      this.$vs.loading()
      try {
        const response = await this.$http.delete(`api/rewardRules/apps/${this.applicationId}/rules/${ruleId}`)
        this.rewardRulesList = this.rewardRulesList.filter(x => x.id != ruleId)
        this.$_notifySuccess('Successfully deleted rule.');

      } catch (error) {
        this.$_notifyFailureByResponseData(error.response.data)
      }

      this.$vs.loading.close();
    },
    onManageRewards () {
      this.$router.push({ name: 'application-tools-reward-list', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
  }
}
</script>

<style>

</style>