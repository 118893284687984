<template>
  <div>
    <feather-icon title="View Details" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onView" />
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEditRule" />
    <feather-icon title="Delete" icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="showDeletePopup=true" />
    <vs-prompt
      title="Delete Rule"
      accept-text="Delete"
      @accept="deleteRule"
      :active.sync="showDeletePopup">

        <div class="vx-row mt-2 mb-2">
          <div class="vx-col w-full">
            <div class="">Are you sure you want to delete the rule - {{params.data.ruleName}}?</div>
          </div>
        </div>
    </vs-prompt>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      showDeletePopup: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    onEditRule () {
      this.params.context.componentParent.onEditRule(this.params.data.id) 
    },
    deleteRule () {
      this.params.context.componentParent.deleteRule(this.params.data.id)
    },
    onView () {
      this.params.context.componentParent.onViewRule(this.params.data.id)
    },
  }
}
</script>
